import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInHours,
  format,
} from "date-fns"

import cs from "date-fns/locale/cs"

export const parseDate = (from: Date, to: Date) => {
  const dateFromEvent = new Date(from)
  const dateToEvent = new Date(to)
  const timeDifference = {
    hours: Math.abs(differenceInHours(dateFromEvent, dateToEvent)),
    days: Math.abs(differenceInCalendarDays(dateFromEvent, dateToEvent)),
    months: Math.abs(differenceInCalendarMonths(dateFromEvent, dateToEvent)),
  }

  if (timeDifference.months > 12) {
    // rozdíl je více než 12 mesícu
    return (
      format(dateFromEvent, "dd. MMMMMM yyyy", { locale: cs }) +
      " - " +
      format(dateToEvent, "dd. MMMMMM yyyy", { locale: cs })
    )
  }
  if (timeDifference.months > 0) {
    // Je v datech větší rozdíl než 0 měsíců
    return (
      format(dateFromEvent, "dd. MMMMMM", { locale: cs }) +
      " - " +
      format(dateToEvent, "dd. MMMMMM", { locale: cs })
    )
  }
  if (timeDifference.days > 0) {
    // Je v datech větší rozdíl než 0 dnů
    return (
      format(dateFromEvent, "dd.") +
      " - " +
      format(dateToEvent, "dd. MMMMMM", { locale: cs })
    )
  }
  if (timeDifference.hours > 12 || timeDifference.hours === 0) {
    // Je v datech větší rozdíl než 12 hodin nebo 0 znamená celodenní akce
    return format(dateFromEvent, "dd. MMMMMM", { locale: cs })
  }
  if (timeDifference.hours > 0) {
    // Je v datech větší rozdíl než 0 hodin
    return (
      format(dateFromEvent, "dd. MMMMMM HH:mm", { locale: cs }) +
      " - " +
      format(dateToEvent, "HH:mm")
    )
  }
  return (
    format(dateFromEvent, "dd. MMMMMM yyyy", { locale: cs }) +
    " - " +
    format(dateToEvent, "dd. MMMMMM yyyy", { locale: cs })
  )
}
