import { AnimatePresence, motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { FiSearch } from "react-icons/fi"
import { useFlexSearch } from "react-use-flexsearch"
import MenuItem from "../sidemenu/MenuItem"
interface SearchItem {
  title: string
  slug: string
  path: string
}
const SearchBar = () => {
  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)

  const [searchQuery, setSearchQuery] = useState("")
  const results = useFlexSearch(
    searchQuery,
    queryData.localSearchPages.index,
    queryData.localSearchPages.store
  )

  return (
    <div className="relative flex w-full gap-5">
      <input
        type="text"
        className="input input-bordered w-full text-lg"
        placeholder="Hledat..."
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
      />
      <button className="btn">
        <FiSearch />
      </button>
      <AnimatePresence initial={false} exitBeforeEnter>
        {results && results.length > 0 && (
          <div className="absolute top-16 z-50 h-full w-full">
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              className="menu rounded-box menu-vertical gap-1 border-2   bg-base-200 p-2  text-xl shadow-xl"
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
            >
              <span className="menu-title pl-2 text-lg font-bold">
                Výsledky hledání:
              </span>
              {results.map((res: SearchItem) => {
                return (
                  <MenuItem
                    key={res.slug}
                    item={{
                      title: res.title,
                      slug: res.slug,
                      path: res.path,
                      related: { slug: res.path },
                      type: "INTERNAL",
                      menuAttached: false,
                    }}
                    activeSlug=""
                  />
                )
              })}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SearchBar
