import { graphql, useStaticQuery } from "gatsby"
import { NavigationTreeItem } from "../interfaces/Navigations"

const useHeaderLinksQuery = () => {
  const headerQuery = useStaticQuery(graphql`
    query NavigationItemsHeader {
      allStrapiNavigationPluginHeader(sort: { fields: order, order: ASC }) {
        nodes {
          title
          slug
          path
          type
          menuAttached
          related {
            slug
          }
        }
      }
    }
  `)
  const result: NavigationTreeItem[] =
    headerQuery?.allStrapiNavigationPluginHeader?.nodes

  return result
}

export default useHeaderLinksQuery
