import React from "react"
import { Link } from "gatsby"
import { NavigationTreeItem } from "../../../interfaces/Navigations"
import { FiExternalLink } from "react-icons/fi"
const MenuItem = ({
  item,
  activeSlug,
}: {
  item: NavigationTreeItem
  activeSlug?: string
}) => {
  if (item.type !== "EXTERNAL") {
    return (
      <li>
        <Link
          className={`${item?.related?.slug === activeSlug ? "active" : ""}`}
          to={`${item?.related?.slug ? `/${item.related.slug}` : item.path}`}
        >
          {item.title}
        </Link>
      </li>
    )
  } else {
    return (
      <li>
        <a href={item.path} target="_blank">
          <FiExternalLink />
          {item.title}
        </a>
      </li>
    )
  }
}

export default MenuItem
