import React from "react"
import SearchBar from "../SearchBar/SearchBar"

import SideMenu from "../sidemenu/SideMenu"

const SidePanel = ({ activeSlug }: { activeSlug?: string }) => {
  return (
    <div className="mb-8 flex flex-col gap-5">
      <SearchBar />
      <SideMenu activeSlug={activeSlug} key="sideMenuforSidePanel" />
    </div>
  )
}

export default SidePanel
