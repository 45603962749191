import { graphql, useStaticQuery } from "gatsby"
import { NavigationTreeItem } from "../interfaces/Navigations"

const useNavigationItemsQuery = () => {
  const navigationItemsQuery = useStaticQuery(graphql`
    query NavigationItemsNavigation {
      allStrapiNavigationPluginNavigation(sort: { fields: order, order: ASC }) {
        nodes {
          title
          slug
          path
          menuAttached
          type
          related {
            slug
          }
          items {
            title
            slug
            menuAttached
            path
            type
            related {
              slug
            }
          }
        }
      }
    }
  `)
  const result: NavigationTreeItem[] =
    navigationItemsQuery?.allStrapiNavigationPluginNavigation?.nodes

  return result
}

export default useNavigationItemsQuery
