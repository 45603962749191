import { Link } from "gatsby"
import React from "react"
import { FiExternalLink } from "react-icons/fi"
import useHeaderLinksQuery from "../../../graphql/useHeaderLinksQuery"

const HeaderLinks = () => {
  const headerlinks = useHeaderLinksQuery()
  return (
    <div className="hidden gap-4 md:flex">
      {headerlinks &&
        headerlinks.map((item, index) => {
          if (item.type === "INTERNAL") {
            return (
              <Link
                key={`header-link-${index}`}
                to={`/${item?.related?.slug ? item?.related?.slug : item.path}`}
                className={`btn px-10 ${
                  item.menuAttached ? "btn-primary" : ""
                }`}
              >
                {item.title}
              </Link>
            )
          }
          if (item.type === "EXTERNAL") {
            return (
              <a
                key={`header-link-${index}`}
                className={`btn px-10 ${
                  item.menuAttached ? "btn-primary" : ""
                }`}
                href={`${item.path}`}
                target="_blank"
              >
                <FiExternalLink />
                <span className="ml-1">{item.title}</span>
              </a>
            )
          }
        })}
    </div>
  )
}

export default HeaderLinks
