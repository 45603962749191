import { graphql, useStaticQuery } from "gatsby"
import { NavigationTreeItem } from "../interfaces/Navigations"

const useFooterLinksQuery = () => {
  const footerQuery = useStaticQuery(graphql`
    query NavigationItemsFooter {
      allStrapiNavigationPluginFooter(sort: { fields: order, order: ASC }) {
        nodes {
          title
          menuAttached
          slug
          path
          type
          related {
            slug
          }
        }
      }
    }
  `)
  const result: NavigationTreeItem[] =
    footerQuery?.allStrapiNavigationPluginFooter?.nodes

  return result
}

export default useFooterLinksQuery
