import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const ImageBar = () => {
  const imageQuery = useStaticQuery(graphql`
    query BaseImageQuery {
      strapiConfiguration {
        baseImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  return (
    <div className="mt-20 max-h-[20rem] w-full overflow-hidden md:mt-0">
      <GatsbyImage
        objectPosition={"50% 50%"}
        objectFit="cover"
        image={
          imageQuery.strapiConfiguration.baseImage.localFile.childImageSharp
            .gatsbyImageData
        }
        alt={"Pozadí"}
      />
    </div>
  )
}

export default ImageBar
