import { Disclosure } from "@headlessui/react"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri"
import useNavigationItemsQuery from "../../../graphql/useNavigationItemsQuery"
import MenuItem from "./MenuItem"

const SideMenu = ({ activeSlug }: { activeSlug?: string }) => {
  const navigationItems = useNavigationItemsQuery()

  return (
    <ul className="menu rounded-box menu-vertical gap-1 bg-base-200  p-2  text-xl">
      {navigationItems &&
        navigationItems.map((navItem) => {
          return (
            <div key={`parent-${navItem.slug}`}>
              {(navItem.items?.length === 0 || !navItem?.items) && (
                <MenuItem
                  key={navItem.slug}
                  item={navItem}
                  activeSlug={activeSlug}
                />
              )}
              {navItem.items && navItem.items?.length > 0 && (
                <Disclosure
                  defaultOpen={navItem.items.some(
                    (arr) => arr.related?.slug === activeSlug
                  )}
                  key={`button-${navItem.slug}`}
                >
                  {({ open }) => (
                    <div>
                      <Disclosure.Button className="w-full">
                        <li>
                          <div
                            className={`${
                              open ? "bg-base-300" : ""
                            } flex flex-nowrap justify-between`}
                          >
                            <span>{navItem.title}</span>
                            <span>
                              {open ? (
                                <RiArrowDropUpFill />
                              ) : (
                                <RiArrowDropDownFill />
                              )}
                            </span>
                          </div>
                        </li>
                      </Disclosure.Button>

                      <Disclosure.Panel static>
                        <AnimatePresence initial={false} exitBeforeEnter>
                          {open && (
                            <motion.ul
                              className="menu-normal my-1 space-y-1 overflow-hidden "
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                            >
                              {navItem.items &&
                                navItem.items.map((subitem) => {
                                  return (
                                    <MenuItem
                                      key={subitem.slug}
                                      item={subitem}
                                      activeSlug={activeSlug}
                                    />
                                  )
                                })}
                            </motion.ul>
                          )}
                        </AnimatePresence>
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              )}
            </div>
          )
        })}
    </ul>
  )
}

export default SideMenu
